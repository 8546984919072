<template>
    <div>
        <van-notice-bar mode="closeable" wrapable :scrollable="false">
            为给您提供更好的服务，请选择您的常住地区。
        </van-notice-bar>
        <p style="font-size: 14px;text-indent: 16px;color: #aaa;margin: 0;padding: 0;line-height: 39px;">账号认证</p>
        <div style="box-shadow: 0 8px 12px #ebedf0;">
            <van-cell-group>
                <van-field v-model="form.channel_name" label="账号等级" placeholder="请选择" readonly @click="typeSelect = true" is-link />
                <van-field v-model="form.account_name" label="账号昵称" placeholder="请输入昵称" />
                <van-field v-model="form.address_name" label="常住地区" placeholder="请选择" is-link readonly @click="areaSelect = true"/>
                <van-field v-if="form.remark" disabled label="审核备注">
                    <template #input>
                        <span style="color: red;">{{form.remark}}</span>
                    </template>
                </van-field>
                <van-field v-model="form.verify_img" label="认证凭证">
                    <template slot="input" slot-scope="props">
                        <div style="display: flex;" id="submitIcon">
                            <div class="van-image van-uploader__preview-image" style="margin-right: 10px;height: 104px;">
                                <van-image :src="demoImg" fit="cover" @click="showDemo(demoImg)" style="height: 80px;width: 80px;display: block;" />
                                <div class="text" style="color: #646566;font-size: 12px;text-align: center;">凭证示例</div>
                            </div>
                            <div class="van-image van-uploader__preview-image" style="margin-right: 10px;height: 104px;" v-if="verified_img">
                                <van-image :src="verified_img" fit="cover" @click="showDemo(verified_img)" style="height: 80px;width: 80px;display: block;" />
                                <div class="text" style="color: #646566;font-size: 12px;text-align: center;">已上传凭证</div>
                            </div>
                            <div class="van-uploader__upload" @click="chooseImg">
                                <i class="van-icon van-icon-photograph van-uploader__upload-icon"></i>
                                <div class="text" style="color: #646566;font-size: 12px;text-align: center;">点击上传</div>
                            </div>
                        </div>
                        <img id="verifyImg" alt="" style="width: 100%;display: none;" @click="chooseImg" />
                    </template>
                </van-field>
                <van-field v-model="form.collection_qr_code" label="收款二维码">
                    <template slot="input" slot-scope="props">
                        <div style="display: flex;" id="submitCode">
                            <div class="van-image van-uploader__preview-image" style="margin-right: 10px;height: 104px;">
                                <van-image :src="demoImg2" fit="cover" @click="showDemo(demoImg2)" style="height: 80px;width: 80px;display: block;" />
                                <div class="text" style="color: #646566;font-size: 12px;text-align: center;">图片示例</div>
                            </div>
                            <div class="van-image van-uploader__preview-image" style="margin-right: 10px;height: 104px;" v-if="qr_code_img">
                                <van-image :src="qr_code_img" fit="cover" @click="showDemo(qr_code_img)" style="height: 80px;width: 80px;display: block;" />
                                <div class="text" style="color: #646566;font-size: 12px;text-align: center;">已上传二维码</div>
                            </div>
                            <div class="van-uploader__upload" @click="chooseQrCode">
                                <i class="van-icon van-icon-photograph van-uploader__upload-icon"></i>
                                <div class="text" style="color: #646566;font-size: 12px;text-align: center;">点击上传</div>
                            </div>
                        </div>
                        <img id="CodeImg" alt="" style="width: 100%;display: none;" @click="chooseQrCode" />
                    </template>
                </van-field>
            </van-cell-group>
        </div>
        <div style="width: 100%;padding:16px;box-sizing: border-box;">
            <van-button color="linear-gradient(to right,#ff6034,#ee0a24)" style="border-radius: 22px;" block @click="submit" :loading="submitting">确定</van-button>
        </div>
        <van-popup v-model="typeSelect" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="channels"
                    value-key="channel_name"
                    :default-index="defaultIndex"
                    title="选择账号类型"
                    @cancel="typeSelect = false"
                    @confirm="onConfirm" />
        </van-popup>
        <van-popup v-model="areaSelect" position="bottom" >
            <van-area title="地区选择" :area-list="areaList" :value="district" @cancel="areaSelect = false" @confirm="onAreaConfirm" />
        </van-popup>
    </div>
</template>

<script>
    import {getQueryVariable} from "../../utils/public";
    import {Toast,Dialog,Notify,ImagePreview} from 'vant';
    import wx from 'weixin-js-sdk';
    import {mapState} from 'vuex';
    import area from "../../utils/area";
    export default {
        name: "TalentApply",
        computed: {
            ...mapState(['talent']),
        },
        data(){
            return{
                form:{
                    channel_id:'',
                    channel_name:'',
                    account_name:'',
                    verify_img:'',
                    apply: true, // 为true为新建，为false为修改
                    address:[],
                    address_name:'',
                    collection_qr_code:'', // 收款码
                },
                demoImg: 'https://lf-public.oss-cn-shenzhen.aliyuncs.com/upload/a3.jpg',
                demoImg2: 'http://lf-public.oss-cn-shenzhen.aliyuncs.com/upload/collection_qr_code.jpg',
                verified_img:'',
                qr_code_img:'',
                defaultIndex:0,
                channels:[],
                typeSelect: false,
                submitting:false,

                areaList:area,
                district:'110101',
                areaSelect:false,
            }
        },
        mounted(){
            this.sdk();
            this.post('/api/client/talent/apply/before').then(result=>{
                // 加载所有的账号等级
                this.channels = result.data.channels;
                // 如果已经认证过达人，加载已上传的信息
                if(result.data.talent){
                    this.form.channel_id = result.data.talent.channel_id;
                    this.form.channel_name = result.data.talent.channel_name;
                    this.form.account_name = result.data.talent.account_name;
                    this.form.remark = result.data.talent.remark;
                    if(result.data.talent.district){
                        this.form.address = [result.data.talent.province,result.data.talent.city,result.data.talent.district];
                        this.form.address_name = result.data.talent.short_name;
                        this.district = result.data.talent.district.toString();
                    }
                    this.verified_img = result.data.talent.verify_img;
                    this.qr_code_img = result.data.talent.collection_qr_code;
                    this.form.apply = false;
                    for(let i = 0; i < this.channels.length; i++){
                        if(result.data.talent.channel_id === this.channels[i].channel_id){
                            this.defaultIndex = i;
                            break;
                        }
                    }
                }
            });
        },
        methods:{
            // 选择达人等级
            onConfirm(value,index){
                this.form.channel_id = value.channel_id;
                this.form.channel_name = value.channel_name;
                this.typeSelect = false;
            },
            onAreaConfirm(columnsNum,index){
                this.form.address = [columnsNum[0].code,columnsNum[1].code,columnsNum[2].code]
                this.form.address_name = columnsNum[0].name + '-' + columnsNum[1].name + '-' + columnsNum[2].name ;
                this.areaSelect = false;
            },
            chooseImg() {
                let that = this;
                wx.chooseImage({
                    count: 1, // 默认9
                    sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
                    success: function (result) {
                        Toast.loading({
                            message: '正在上传...',
                            forbidClick: true,
                            duration: 0,
                        });
                        wx.uploadImage({
                            localId: result.localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
                            isShowProgressTips: 1, // 默认为1，显示进度提示
                            success: function (res) {
                                // 保存服务器端ID
                                that.form.verify_img = res.serverId;
                                // 预览图片
                                if(/(Android)/i.test(navigator.userAgent)){
                                    document.getElementById('verifyImg').setAttribute('src', result.localIds[0]);
                                    document.getElementById('verifyImg').style.display = 'block';
                                    document.getElementById('submitIcon').style.display = 'none';
                                }else{
                                    // ios无法预览，需要base64编码
                                    wx.getLocalImgData({
                                        localId: result.localIds[0], // 图片的localID
                                        success: function (res) {
                                            document.getElementById('verifyImg').setAttribute('src',res.localData);
                                            document.getElementById('verifyImg').style.display = 'block';
                                            document.getElementById('submitIcon').style.display = 'none';
                                        }
                                    });
                                }
                                Toast.clear();
                            },
                            fail: function (res) {
                                Toast.clear();
                                console.log('upload fail');
                            },
                        });
                    },
                    fail: function (res) {
                        Toast('choose image error');
                        that.post('/api/common/log',{chooseImageError:res}).then(result=>{
                            Toast('choose image error2');
                        });
                    },
                });
            },
            chooseQrCode() {
                let that = this;
                wx.chooseImage({
                    count: 1, // 默认9
                    sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
                    success: function (result) {
                        Toast.loading({
                            message: '正在上传...',
                            forbidClick: true,
                            duration: 0,
                        });
                        wx.uploadImage({
                            localId: result.localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
                            isShowProgressTips: 1, // 默认为1，显示进度提示
                            success: function (res) {
                                // 保存服务器端ID
                                that.form.collection_qr_code = res.serverId;
                                // 预览图片
                                if(/(Android)/i.test(navigator.userAgent)){
                                    document.getElementById('CodeImg').setAttribute('src', result.localIds[0]);
                                    document.getElementById('CodeImg').style.display = 'block';
                                    document.getElementById('submitCode').style.display = 'none';
                                }else{
                                    // ios无法预览，需要base64编码
                                    wx.getLocalImgData({
                                        localId: result.localIds[0], // 图片的localID
                                        success: function (res) {
                                            document.getElementById('CodeImg').setAttribute('src',res.localData);
                                            document.getElementById('CodeImg').style.display = 'block';
                                            document.getElementById('submitCode').style.display = 'none';
                                        }
                                    });
                                }
                                Toast.clear();
                            },
                            fail: function (res) {
                                Toast.clear();
                                console.log('upload fail');
                            },
                        });
                    },
                    fail: function (res) {
                        Toast('choose image error');
                        that.post('/api/common/log',{chooseImageError:res}).then(result=>{
                            Toast('choose image error2');
                        });
                    },
                });
            },
            showDemo(img){
                ImagePreview({
                    images: [img],
                    closeable: true,
                    closeOnPopstate:true,
                });
            },
            submit(){
                let form = this.form;
                // 验证必填信息
                if(!form.channel_id){
                    Notify('请选择账号类型');
                    return;
                }
                if(!form.account_name){
                    Notify('请输入账号昵称');
                    return;
                }
                if(form.apply && !form.verify_img){
                    Notify('请上传认证凭证');
                    return;
                }
                if(form.apply && !form.collection_qr_code){
                    Notify('请上传收款码截图');
                    return;
                }
                if(form.address.length !== 3){
                    Notify('请选择您的常住地区');
                    return;
                }
                if(!form.apply){
                    Dialog.confirm({
                        title: '提示',
                        message: '修改个人信息后，您的认证状态将修改为待审核，待审核期间无法接单，已接任务不受影响',
                    }).then(() => {
                        this.submitRequest();
                    });
                } else {
                    this.submitRequest();
                }
            },
            submitRequest(){
                this.submitting = true;
                this.post('/api/client/talent/apply',this.form).then(result=>{
                    if(result.code === 0){
                        Dialog.alert({
                            title:'提交成功',
                            message:result.msg,
                        }).then(()=>{
                            this.$router.back();
                        });
                    } else {
                        Toast(result.msg);
                    }
                    this.submitting = false;
                })
            },
            sdk(){
                // 接入jssdk
                if(/(Android)/i.test(navigator.userAgent)){
                    this.post('/api/common/jsapi',{url:location.href.split('#')[0]}).then(result=>{
                        if(result.code === 0){
                            wx.config(result.data);
                        }
                    });
                    wx.ready(function () {
                        console.log('wx.ready');
                    });
                    wx.error(function (res) {
                        console.log(res);
                    });
                }
            },
        }
    }
</script>

<style scoped>

</style>